import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // If using React Router for navigation

// Import your icons here
import barIcon from "../../images/bar-icon.svg";
import checkInIcon from "../../images/checkin-icon.svg";
import insightsIcon from "../../images/insights-icon.svg";
import { useSidebar } from './SidebarProvider';
import UserService from '../../services/UserService';
const Header = () => {
  const location = useLocation();
  const [isPremium, setIsPremium] = useState("");
  let data = JSON.parse(localStorage.getItem("isSignedIn"))
  const [subscription_id] = useState(data?data.subscription_id:null);
  const { handleOpenSidebar } = useSidebar();
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        let profile = await UserService.getProfile();

        if (profile.is_premium) {
          setIsPremium(true);
        } else {
          setIsPremium(false);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, [subscription_id]);

  return (
    <header className={`${location.pathname === '/' ? '' : "fixed top-0 left-0 w-full z-[99]"}`}>
      <nav className="bg-white w-full z-20 top-0 start-0 border-b border-gray-200">
        <div className="flex flex-wrap items-center justify-between mx-auto px-4">
          <div className="flex items-center xs:justify-between 2xl:justify-start flex-1 xs:pt-3 xs:pb-1 2xl:py-0">
            <button
              id="navbar-brand"
              className="flex items-center flex-1"
              onClick={handleOpenSidebar}
            >
              <span className="pr-3 w-[32px]">
                <img src={barIcon} className="w-full" alt="" />
              </span>
              <div className="navbar-logo">
                <h1 className="font-bold text-[22px] leading-none">Rhea</h1>
                <p className="leading-none text-[12px] text-pink-900 font-semibold text-left">{subscription_id ?isPremium?'Premium':'Plus':"Free"}</p>
              </div>
            </button>
            <button className="2xl:hidden flex-1 flex justify-end">
              {/* <img src={editIcon} alt="" /> */}
            </button>
          </div>
          <div className="xs:w-full 2xl:w-[auto] flex 2xl:flex-1 justify-center">
            <ul className="flex xs:w-full xs:max-w-screen-sm 3xl:max-w-[450px]">
              <li className={`2xl:min-w-[150px] xs:min-w-[auto] text-center flex-1 ${location.pathname === '/' ? 'active' : ''}`} role="presentation">
                <Link to="/" className="xs:py-4 2xl:p-4 inline-flex items-center font-semibold">
                  <span className="pr-2">
                    <img src={checkInIcon} alt="" />
                  </span>
                  Check in
                </Link>
                {location.pathname === '/' && (
                  <span className="w-full h-[3px] bg-gradient-to-l from-pink-800 to-pink-900 flex"></span>
                )}
              </li>
              <li className={`2xl:min-w-[150px] xs:min-w-[auto] text-center flex-1 ${location.pathname === '/insights' ? 'active' : ''}`} role="presentation">
                <Link to="/insights" className="xs:py-4 2xl:p-4 inline-flex items-center font-semibold">
                  <span className="pr-2">
                    <img src={insightsIcon} alt="" />
                  </span>
                  Insights  <span className="bg-gradient-to-l from-pink-800 to-pink-900 rounded-full px-2 text-white py-[2px] text-base font-normal ms-2">Beta</span>
                </Link>
                {location.pathname === '/insights' && (
                  <span className="w-full h-[3px] bg-gradient-to-l from-pink-800 to-pink-900 flex"></span>
                )}
              </li>
            </ul>
          </div>
          <div className="flex-1 justify-end xs:hidden 2xl:flex">
            {/* <button>
              <img src={editIcon} alt="" />
            </button> */}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
