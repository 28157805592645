import React, { useEffect, useState } from "react";
import { analytics   } from '../../config/firebase';
import { logEvent } from "firebase/analytics";

const SurveyPage = ({ step, totalSteps, question, onNext,error }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
        setSelectedOption(null)
        logEvent(analytics, `survey_${step + 1}_page_view` , {category : `onboarding_survey_${step}` });
    },[step])
    console.log(selectedOption,"selectedOption")
    return (<>
        <main className="bg-primary w-full min-h-screen h-auto">
            <section className="container h-full px-6 lg:px-4 py-8 lg:py-10">
                <article className="w-full md:w-1/2 flex flex-col mx-auto min-h-[60vh]">
                    <p className="text-[16px] text-gray-800 mb-3">{`${step} of ${totalSteps}`}</p>
                    <p className="mb-4 lg:mb-8 text-[28px] leading-snug">{question.question_text}</p>
                    <div className="grid grid-cols-1 gap-4">
                        <p className="text-[14px]">select the answer that applies.</p>

                        {question.options.map((option) => (
                            <button
                                key={option.id}
                                className={`h-[64px] w-full border border-blue-900 rounded-full inline-flex 
                                    items-center justify-center text-[20px] hover:bg-blue-900 hover:text-white 
                                    focus:bg-blue-900 focus:text-whit ${selectedOption?.id === option.id
                                        ? "bg-blue-900 text-white"
                                        : ""
                                    }`}
                                onClick={() => {
                                    setSelectedOption(option)
                                    logEvent(analytics, `survey_${step + 1}_any_option_selected` , {category : `onboarding_survey_${step}` });
                                }}
                            >
                                {option.option_text}
                            </button>
                        ))}
                    </div>
                    </article>
                    <div className="w-full md:w-1/2 mx-auto">
                    <p className="mt-4 mt-auto text-[14px] leading-tight text-gray-500">Your responses help us address your questions and concerns better by personalizing Rhea to your needs. Your information is kept private and secure.</p>
                    {error && <p className="text-red-500 text-center">{error}</p>}
                    <button disabled={!selectedOption}
                        onClick={() => {
                            logEvent(analytics, `survey_${step + 1}_cta_clicked` , {category : `onboarding_survey_${step}` });
                            onNext(selectedOption)
                        }} 
                        className={"transition-all duration-300 ease-in-out delay-150 h-[64px] flex items-center justify-center rounded-full my-6 text-[24px] tracking-[-0.3px] w-80 text-white ms-auto w-full "+ (!selectedOption ?'bg-gray-800 cursor-not-allowed':'bg-gradient-to-l from-pink-800 to-pink-900 shadow-lg')}>
                           {step === totalSteps ? `Let's Chat`: 'Next'}
                            </button>
                    <div className="flex justify-between">
                        <p className="xs:text-[12px] lg:text-[14px] text-gray-800">©{new Date().getFullYear()} Be Human(e), co.</p>
                        <ul className="flex items-center">
                            <li className="inline-block pr-6">
                                <a href="mailto:support@behumane.co" className="xs:text-[12px] lg:text-[14px] underline underline-offset-4 decoration-gray-300" onClick={() => {
                                    logEvent(analytics, `survey_${step + 1}_help_clicked` , {category : `onboarding_survey_${step}` }) }} >Need Help?</a>
                            </li>
                            <li>
                                <a href="https://www.behumane.ai/privacy-policy" target="_blank" onClick={() => {
                                    logEvent(analytics, `survey_${step + 1}_privacy_clicked` , {category : `onboarding_survey_${step}` });
                                }}rel="noreferrer" className="xs:text-[12px] lg:text-[14px] underline underline-offset-4 decoration-gray-300">Privacy Policy</a>
                            </li>
                        </ul>
                    </div>
                {/* </article> */}
                </div>
            </section>
        </main>
    </>
    );
};




export default SurveyPage;
