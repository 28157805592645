import React, { useState, useEffect } from "react";
import copyIcon from "../../images/copy-icon.svg";
import likeIcon from "../../images/like-icon.svg";
import dislikeIcon from "../../images/dislike-icon.svg";
import rehaAvatarIcon from "../../images/reha-avatar.svg";
import likeFillIcon from "../../images/like-fill-grey.svg";
import unLinkFillIcon from "../../images/dislike-fill-grey.svg";
import ReactMarkdown from "react-markdown";
import { analytics   } from '../../config/firebase';
import { logEvent } from "firebase/analytics";

// SVG check icon
const CheckIcon = () => (
    <span style={{ fontFamily: "Material Symbols Outlined", fontSize: "16px" }}>
        check
    </span>
);

// SVG thumbs up icon (replaced with checkmark if rated)
const ThumbsUpIcon = ({ rated, onClick }) => {
    return rated === 1 ? <img src={likeFillIcon} alt="" /> : 
   <> {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */} 
    <a
        onClick={onClick}
        style={{
            cursor: rated === null ? "pointer" : "default"
        }}
    >
        <img src={likeIcon} alt="" />
    </a></>
};

// SVG thumbs down icon (replaced with checkmark if rated)
const ThumbsDownIcon = ({ rated, onClick }) => (
    rated === -1 ? (
        <img src={unLinkFillIcon} alt="" />)
        : ( <>{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
            onClick={onClick}
            style={{
                cursor: rated === null ? "pointer" : "default"
            }}
        >
            <img src={dislikeIcon} alt="" />
        </a></>)

);


const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

const FeedbackForm = ({ onClose, feedbackType, messageId }) => {
    const [selectedReasons, setSelectedReasons] = useState([]);
    // const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsLargeScreen(window.innerWidth >= 768);
    //     };

    //     window.addEventListener("resize", handleResize);
    //     return () => window.removeEventListener("resize", handleResize);
    // }, []);


    const reasons =
        feedbackType === "positive"
            ? ["Makes me feel better", "Helps with my problem", "It's something new", "It's like she knows me"]
            : ["Not Relevant to the Topic", "Contains Inaccurate Information", "Lacks Sufficient Detail", "Not personalized enough"];

    const handleReasonToggle = (reason) => {
        setSelectedReasons((prevSelectedReasons) =>
            prevSelectedReasons.includes(reason)
                ? prevSelectedReasons.filter((r) => r !== reason)
                : [...prevSelectedReasons, reason]
        );
    };

    const handleSubmit = async () => {
        const rating = feedbackType === "positive" ? 1 : -1;

        try {
            const response = await fetch(`${API_BASE_URL}/chat/messages/rate`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    message_id: messageId,
                    rating: rating,
                    reasons: selectedReasons,
                }),
            });

            const data = await response.json();
            console.log("Rating submitted successfully:", data);
        } catch (error) {
            console.error("Error submitting rating:", error);
        }

        onClose(rating);
    };

    return (
        <div className="bg-white rounded-[15px] w-100 rounded-[10] p-4 mt-5 shadow-lg border border-pink-200 relative suggetions">
            <p className="mb-2 font-semibold">Tell us more:</p>
            <ul className="flex flex-col lg:flex-row gap-2">
                {reasons.map((reason, index) => {
                    return <li className="inline-flex flex-1" key={index}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#" role-="button" onClick={() => handleReasonToggle(reason)} 
                        className={selectedReasons.includes(reason) ? "bg-gradient-to-l from-pink-800 to-pink-900 rounded-[10px] px-[20px] py-2 text-[15px] text-white w-full text-center hover:bg-gradient-to-l hover:from-pink-800 hover:to-pink-900 hover:text-white":"border border-pink-800 rounded-[10px] px-[20px] py-2 text-[15px] text-purple-800 w-full text-center hover:bg-gradient-to-l hover:from-pink-800 hover:to-pink-900 hover:text-white"}
                        >{reason}</a>
                    </li>
                })}
            </ul>
            <button
                style={{
                    marginTop: "12px",
                    cursor: "pointer",
                    fontSize: "16px",
                }}
                onClick={handleSubmit}
            >
                Submit
            </button>
            <button
                style={{
                    marginTop: "12px",
                    cursor: "pointer",
                    float: "right",
                    fontSize: "16px",
                }}
                onClick={() => onClose(null)}
            >
                Close
            </button>
        </div>
    );
};

const ChatMessage = ({ message }) => {
    const [iconType, setIconType] = useState("copy");
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [iconsVisible, setIconsVisible] = useState(false);

    // Initialize feedback states based on the message's rating
    const [positiveFeedbackGiven, setPositiveFeedbackGiven] = useState(
        message.rating && message.rating.rating === 1
    );
    const [negativeFeedbackGiven, setNegativeFeedbackGiven] = useState(
        message.rating && message.rating.rating === -1
    );

    // Initialize the feedbackType state
    const [feedbackType, setFeedbackType] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIconsVisible(true);
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleMouseEnter = () => {
        if (!showFeedbackForm && window.innerWidth >= 768) {
            setIconsVisible(true);
        }
    };

    const handleMouseLeave = () => {
        if (window.innerWidth >= 768) {
            setIconsVisible(false);
        }
    };

    const copyToClipboard = () => {
        logEvent(analytics, "message_copied" , {page : window.location.pathname , category : "use_sharing_features" });
        navigator.clipboard
            .writeText(message.content)
            .then(() => {
                setIconType("check");
                setTimeout(() => {
                    setIconType("copy");
                    if (window.innerWidth >= 768) {
                        setIconsVisible(false);
                    }
                }, 1000);
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };

    const handleThumbsDownClick = () => {
        setFeedbackType("negative");
        setShowFeedbackForm(true);
        setIconsVisible(false);
    };

    const handleThumbsUpClick = () => {
        setFeedbackType("positive");
        setShowFeedbackForm(true);
        setIconsVisible(false);
    };

    const handleCloseFeedbackForm = (rating) => {
        setShowFeedbackForm(false);
        if (rating === 1) {
            setPositiveFeedbackGiven(true);
        } else if (rating === -1) {
            setNegativeFeedbackGiven(true);
        }
        if (window.innerWidth <= 768) {
            setIconsVisible(true);
        }
    };

    // Disable both buttons if feedback has been given
    const feedbackGiven = positiveFeedbackGiven || negativeFeedbackGiven;

    return (
        <div
            className={`flex py-3 ${message.role === "user" ? " items-end justify-end  " : "items-start justify-start "}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {message.role !== "user" && <img src={rehaAvatarIcon} alt="" />}
            <div className="pl-3" >
                <p className={message.role === "user" ? "text-white lg:text-[17px] leading-snug bg-gradient-to-l from-purple-900 to-purple-800 px-5 py-4 rounded-[20px]" : "lg:text-[18px] leading-normal"}> 
                <ReactMarkdown>{message.content.includes("\n") ?  message.content.replace(/\n/g, "  \n"):message.content}</ReactMarkdown></p>
                {message.source === "sms" && (
                    <div style={{ fontSize: "12px", color: "#777", marginTop: "5px", textAlign: "right" }}>Sent on SMS</div>
                )}
                 {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {message.role === "user" ? (
                    <>
                    <button className="flex justify-end mt-3 w-full" onClick={copyToClipboard}   style={{ opacity: iconsVisible ? 1 : 0, transition: "opacity 0.3s ease-in-out" }}>
                        {iconType === "copy" ? <img src={copyIcon} alt="" /> : <CheckIcon />}
                    </button>
                    </>
                ) : (
                    <ul className="flex mt-3" style={{ opacity: iconsVisible ? 1 : 0, transition: "opacity 0.3s ease-in-out" }}>
                        <li className="inline-block me-3 " onClick={copyToClipboard} role="button" >
                            {iconType === "copy" ? <img src={copyIcon} alt="" /> : <CheckIcon />}
                        </li>
                        <li className="inline-block me-3">
                            <ThumbsUpIcon rated={positiveFeedbackGiven ? 1 : feedbackGiven ? null : 0} onClick={feedbackGiven ? null : handleThumbsUpClick} />

                        </li>
                        <li className="inline-block me-3">
                            <ThumbsDownIcon rated={negativeFeedbackGiven ? -1 : feedbackGiven ? null : 0} onClick={feedbackGiven ? null : handleThumbsDownClick} />
                        </li>
                    </ul>)}
                {showFeedbackForm && (
                    <FeedbackForm
                        onClose={handleCloseFeedbackForm}
                        isVisible={showFeedbackForm}
                        feedbackType={feedbackType}
                        messageId={message.id}
                    />
                )}
            </div>

        </div>
    )
};

const CheckInChatWindow = ({ messages, isTyping }) => {
    return <div className="lg:py-4 xs:py-1 pb-8 flex flex-col  overflow-auto" >
        {messages.map((message, index) => (
            <ChatMessage key={message.id} message={message} />
        ))}
        {isTyping && <div className="typing-indicator">...</div>}
    </div>
}

export default CheckInChatWindow;