import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import arrowLeftIcon from "../../images/arrow-left.svg";
import rehaLarge from "../../images/reha-large.png";
import signInIcon from "../../images/signin-icon.svg";
import { analytics } from '../../config/firebase';
import { logEvent } from "firebase/analytics";


const SignIn = () => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    // const [premiumCode, setPremiumCode] = useState("");
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [error, setError] = useState("");
    // const [premiumCodeError, setPremiumCodeError] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params = searchParams.get('p');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";


    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const handlePhoneChange = (event) => {
        const value = event.target.value.replace(/[^\d]/g, '');
        if (value.length <= 10) {
            setPhone(value);
        }
    };

    const handleEmailSignIn = async () => {
        try {
            const requestBody = {
                email: email,
                payment: params === 'payment'
            };

            if (phone.length === 10) {
                requestBody.phone_number = phone;
                localStorage.setItem('phone', phone);
            }


            const response = await fetch(`${API_BASE_URL}/auth/login/email`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();

            if (response.ok && responseData.response === "OK") {
                logEvent(analytics, "sign_in_link_generated", {
                  category : "activation_rate",
                  page : window.location.pathname
                });
                logEvent(analytics, "authentication_session", {
                  category : "activation_rate",
                  page : window.location.pathname,
                  email: email,
                  session_start_time: new Date().toISOString()
                });

                navigate("/code");
            } else {
                if (responseData.error) {
                    setError(responseData.error);
                } else if (responseData.premium_code_error) {
                    // setPremiumCodeError("The code is invalid. Try a different code.");
                } else {
                    setError(responseData.message || "Unknown error");
                }
            }
        } catch (error) {
            console.error("Error during sign-in:", error);
            setError("An error occurred during sign-in. Please try again.");
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setError("");
        // setPremiumCodeError("");
        handleEmailSignIn();
    };
    const isButtonDisabled = !validateEmail(email) || !isTermsChecked || (phone.length > 0 && phone.length !== 10);
    return <main className="h-full lg:bg-primary">
        <section className="container h-full">
            <div className="flex w-full h-full flex-col justify-start xs:w-[100%] md:w-[540px] lg:w-[640px] mx-auto ">
                <div className="flex w-full h-[40px]"></div>
                <div className="my-2">
                    <Link to="/" className="flex items-center font-semibold text-gray-800 xs:mb-0 lg:mb-3">
                        <img src={arrowLeftIcon} className="mr-3" alt="" />
                        Back to Chat
                    </Link>
                </div>
                <div className="xs:w-[100%] lg:w-[640px] mx-auto relative">
                    <div className="absolute z--1 translate-x-[-50%] bottom-10 xs:hidden lg:block">
                        <img src={rehaLarge} alt="" />
                    </div>
                    <div className="lg:bg-white rounded-[20px] xs:py-7 lg:py-[52px] lg:shadow-2xl xs:px-4 lg:px-20 relative z-1">
                        <div className="flex flex-col items-center">
                            <img src={signInIcon} alt="" />
                            <h2 className="font-bold text-[20px] mt-5">Sign In</h2>
                            <p className="text-gray-800 text-[15px] font-medium text-center mt-1 leading-normal">Be Human(e) is your safe space to share your thoughts, <br className="xs:hidden lg:block" /> feelings, and questions.</p>
                        </div>
                        <div className="w-full h-[36px]"></div>
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col xs:mb-4 lg:mb-7">
                                <label htmlFor="email" className="font-semibold mb-2">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="example@email.com"
                                    className="bg-white border-1 border-gray-200 rounded-full w-full h-[52px] font-medium pl-6 focus:border-pink-800 focus:ring focus:ring-pink-100"
                                    required
                                />
                            </div>
                            <div className="flex flex-col xs:mb-4 lg:mb-7">
                                <label htmlFor="phone" className="font-semibold mb-2">Phone (Optional)</label>
                                <div className="relative flex items-center">
                                    <input type="tel"
                                        value={phone}
                                        id="phone"
                                        onChange={handlePhoneChange}
                                        placeholder="Enter your phone number"
                                        className="bg-white border-1 border-gray-200 rounded-full w-full h-[52px] font-medium focus:border-pink-800 focus:ring focus:ring-pink-100 pl-12" />
                                    <span className="absolute font-semibold left-5">+1</span>
                                </div>
                            </div>
                            {/* <div className="flex flex-col mb-5">
                            <label htmlFor="premiumCode" className="font-semibold mb-2">Premium Code</label>
                            <div className="relative flex items-center">
                                <input type="text" id="premiumCode" placeholder="Enter 6 character code"
                                    maxLength={6}
                                    value={premiumCode}
                                    onChange={(e) => setPremiumCode(e.target.value)}
                                    className="bg-white border-1 border-gray-200 rounded-full w-full h-[52px] font-medium focus:border-pink-800 focus:ring focus:ring-pink-100 pl-6 "/>
                            </div>
                            {premiumCodeError && (
                                <p className="text-red-500 text-sm mb-2">{premiumCodeError}</p>
                            )}
                        </div> */}
                            <div className="flex items-start">
                                <input type="checkbox"
                                    id="terms"
                                    checked={isTermsChecked}
                                    onChange={(e) => setIsTermsChecked(e.target.checked)}
                                    required

                                    className="w-[22px] h-[22px] text-secondary bg-white border-gray-200 rounded focus:ring-white focus:ring-0 text-[18px]" />
                                <label htmlFor="terms" className="ms-3 text-[15px] text-gray-800 leading-snug">I agree to Be Human(e)'s <a href="https://www.behumane.ai/privacy-policy" target="_blank" className="underline underline-offset-4 decoration-gray-300" rel="noreferrer">  Privacy Policy</a> and <a href="https://www.behumane.ai/terms-and-conditions" className="underline underline-offset-4 decoration-gray-300" target="_blank" rel="noreferrer">Terms and Conditions</a>.</label>
                            </div>
                            {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
                            <button type="submit"
                                className={"transition-all w-full duration-300 ease-in-out delay-150 h-[60px] flex items-center justify-center rounded-full mt-[52px] text-[18px] font-bold tracking-[-0.3px]  text-white  " + (isButtonDisabled ? "bg-gray-800" : "bg-gradient-to-l from-pink-800 to-pink-900 shadow-custom-btn")}
                                disabled={isButtonDisabled} >
                                Continue with email
                            </button>
                        </form>
                    </div>
                  
                </div>
                <div className="flex xs:flex-col sm:flex-row items-center justify-between py-4 mt-auto">
                        <p className="text-[14px] text-gray-800">©{new Date().getFullYear()} Be Human(e), co.</p>
                        <ul className="flex items-center">
                            <li className="inline-block pr-6">
                                <a href="mailto:support@behumane.co" className="text-[14px] underline underline-offset-4 decoration-gray-300">Need Help?</a>
                            </li>
                            <li>
                                <a target="_blank" rel="noreferrer" href="https://www.behumane.ai/privacy-policy" className="text-[14px] underline underline-offset-4 decoration-gray-300">Privacy Policy</a>
                            </li>
                        </ul>
                    </div>
            </div>
        </section>
    </main>
}

export default SignIn