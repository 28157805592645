import React, { useState, useEffect } from "react";
import closeIcon from "../../images/close-icon.svg";
import callIcon from '../../images/call.svg';
function PhoneNumberVerificationModal({ isOpen, onClose, onVerify, errorMessage }) {
  const [verificationCode, setVerificationCode] = useState("");
  // const [hoverResend, setHoverResend] = useState(false);

  const sendCode = async () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";
    try {
      const response = await fetch(`${API_BASE_URL}/auth/send_sms_code`, {
        method: 'GET',
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok) {
        console.log('SMS code sent successfully:', data);
      } else {
        throw new Error('Failed to send SMS code');
      }
    } catch (error) {
      console.error('Error sending SMS code:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      sendCode();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleVerificationCodeChange = (e) => {
    const code = e.target.value.replace(/\D/g, '');
    if (code.length <= 6) {
      setVerificationCode(code);
      if (code.length === 6) {
        onVerify(code); // Call the onVerify function passed from the parent component
      }
    }
  };

  return (
    <>
      <div id="verify-number" data-modal-backdrop="static" tabIndex="-1" className={" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 xs:h-[100%] lg:h-[auto] " + (isOpen ? "flex" : "hidden")}>
        <div className="relative w-full justify-center max-w-full md:max-w-[500px] xs:h-full md:h-[auto] md:max-h-full bg-white py-16 shadow-2xl xs:px-10 lg:px-20 md:rounded-[20px] animate-fadeInSlide flex items-center">
          <div className="w-full">
            <div className="flex flex-col items-center">
              <img src={callIcon} className="w-[76px] h-[76px]" alt="" />
              <h2 className="font-bold text-[24px] mt-3">Verify Your Phone Number</h2>
            </div>
            <button onClick={onClose} data-modal-hide="verify-number" className="absolute top-10 right-10">
              <img src={closeIcon} alt="" />
            </button>
            <div className="h-[32px] w-full"></div>
            <p className="text-center font-medium"> Enter the 6-digit code sent to your phone:</p>
            <div className="h-[20px] w-full"></div>
            <input type="text"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              maxLength={6}
              placeholder=" "
              className="bg-white border-1 border-gray-200 rounded-full w-full h-[52px] font-medium pl-6 focus:border-pink-800 focus:ring focus:ring-pink-100" />
            {errorMessage && <p style={styles.error}>{errorMessage}</p>}
            <div className="h-[20px] w-full"></div>
            <button
              // onMouseEnter={() => setHoverResend(true)}
              // onMouseLeave={() => setHoverResend(false)}
              onClick={sendCode}
              className="flex w-full justify-center rounded-full mt-[16px] text-[16px] font-semibold tracking-[-0.3px] text-gray-800">Resend Code</button>

          </div>
        </div>
      </div>
      {isOpen && <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>}
    </>
  );
}

const styles = {
  error: {
    color: "red",
    marginTop: "10px",
  },
};

export default PhoneNumberVerificationModal;
