import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { initFlowbite } from 'flowbite';
import userIcon from "../../images/user-icon.svg";
// import settingIcon from "../../images/setting-icon.svg";
import closeIcon from "../../images/close-icon.svg";
// import rehaSidebarLogo from "../../images/reha-sidebar-logo.svg";
import logoutIcon from "../../images/logout.svg";
import { analytics } from '../../config/firebase';
import { logEvent } from "firebase/analytics";
import UserService from "../../services/UserService";
import { useSidebar } from "./SidebarProvider";

const Sidebar = ({ setSignOutModal }) => {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const { isSidebarOpen, handleCloseSidebar } = useSidebar();
    const [ isEmail, setIsEmail ] = useState(null);
    const drawerRef = useRef(null);
    const [isPremium, setIsPremium] = useState("");
     let data = JSON.parse(localStorage.getItem("isSignedIn"))
     const [subscription_id,setSubscription_id] = useState(data?data.subscription_id:null); 
    useLayoutEffect(() => {
        const drawer = drawerRef.current;

        // Set initial state to avoid flickering
        if (isSidebarOpen) {
            drawer.classList.add("shadow-2xl");
            drawer.classList.remove("-translate-x-full");
        } else {
            drawer.classList.remove("shadow-2xl");
            drawer.classList.add("-translate-x-full");
        }
    }, [isSidebarOpen]);

    useEffect(() => {
        initFlowbite();
    }, []);

    useLayoutEffect(() => {
        const fetchSignInStatus = async () => {
            const data = await UserService.getSignInStatus();
            setIsSignedIn(data.authenticated);
            setSubscription_id(data.subscription_id)
            setIsEmail(data.email)
            localStorage.setItem("isSignedIn", JSON.stringify(data));
        };

        fetchSignInStatus();
    }, []);
    useEffect(() => {
        const fetchProfile = async () => {
          try {
            let profile = await UserService.getProfile();
    
            if (profile.is_premium) {
              setIsPremium(true);
            } else {
              setIsPremium(false);
            }
          } catch (error) {
            console.error("Error fetching profile:", error);
          }
        };
    
        fetchProfile();
      }, [subscription_id]);

    const setSignOutModalWithClose = () => {
        handleCloseSidebar();
        setSignOutModal(true);
    };

    return (
        <React.Fragment>
            <aside id="sidebar" ref={drawerRef}
                className="fixed top-0 lg:left-0 h-screen p-6 px-8 overflow-y-auto transition-transform bg-white lg:w-[350px] xs:w-[300px] dark:bg-gray-800 z-[999]"
                tabIndex="-1"
            >
                <div className="flex justify-between items-center">
                    <div className="navbar-logo">
                        <h1 className="font-bold text-[20px] leading-none">Rhea</h1>
                        <p className="leading-none text-[12px] text-pink-900 font-semibold text-left">{subscription_id ?isPremium?'Premium':'Plus':"Free"}</p>
                    </div>
                    {/* <img src={rehaSidebarLogo} alt="" /> */}
                    <button onClick={handleCloseSidebar} >
                        <img src={closeIcon} alt="" />
                    </button>
                </div>

                <div className="w-full h-[44px] block"></div>
                <nav>
                    {/* <p className="font-semibold fs-[14px] text-gray-800 mb-5">General</p> */}
                    <ul>
                        {!isSignedIn ? <Link
                            to="/login"
                            className="flex items-center fs-[15px] py-3"
                            onClick={() => {
                                logEvent(analytics, "sign_in_page_view", {
                                    page: window.location.pathname,
                                    category: "activation_rate",
                                });
                            }}
                        >
                            <img src={userIcon} className="mr-3" alt="" />
                            Sign In
                        </Link> :
                            <li>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a role="button" onClick={setSignOutModalWithClose} className="flex items-center fs-[15px] pt-3">
                                    <img src={logoutIcon} className="mr-3" alt="" />
                                    Sign Out
                                </a>
                                <span>{isEmail}</span>
                            </li>}
                    </ul>
                </nav>
            </aside>
        </React.Fragment>
    )
}

export default Sidebar;