import React from 'react';
import rheaLogo from "../../images/rhea-4x.png";

const WelcomeSection = ({ onNext }) => (
  <section className="container h-[auto] lg:h-full px-6 lg:px-4 py-8 lg:py-16">
    <article className="w-full h-full flex flex-col">
      <div className="flex-1 shrink-0">
        <img src={rheaLogo} className="max-w-full mx-auto pt-4 float-end ps-[60px] mb-[80px] hidden lg:flex" alt="Rhea Logo" />
        <h1 className="text-6xl lg:text-8xl font-bold mb-6">Always know what to do</h1>
        <img src={rheaLogo} className="w-1/2 flex lg:hidden mx-auto" alt="Rhea Logo" />
        <p className="text-[28px] leading-[38px] mt-4">
        Hello! My name is Rhea 👋 <br className="hidden lg:inline-block" /><br className="hidden lg:inline-block" />
        I'm here to navigate you through <span className="font-bold">modern parenting challenges </span>
        powered by decades of expert knowledge.
        </p>
      </div>
      <button
        onClick={onNext}
        className="transition-all duration-300 ease-in-out delay-150 bg-gradient-to-l from-pink-800 to-pink-900 h-[64px] flex items-center justify-center rounded-full mt-8 lg:mt-auto text-[24px] tracking-[-0.3px] hover:bg-gradient-to-r hover:from-pink-800 hover:to-pink-900 w-full lg:w-80 text-white shadow-lg mx-auto shrink-0"
      >
        Sounds good
      </button>
    </article>
  </section>
);

export default WelcomeSection;
