import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { initFlowbite } from 'flowbite';
// import ventIcon from "../../images/vent-icon.svg";
// import copyIcon from "../../images/copy-icon.svg";
// import likeIcon from "../../images/like-icon.svg";
// import dislikeIcon from "../../images/dislike-icon.svg";
// import rehaAvatarIcon from "../../images/reha-avatar.svg";
// import barIcon from "../../images/bar-icon.svg";
// import checkInIcon from "../../images/checkin-icon.svg";
// import ventIcon from "../../images/vent-icon.svg";
// import insightsIcon from "../../images/insights-icon.svg";
// import editIcon from "../../images/edit-icon.svg";
import shareIcon from "../../images/share-icon.svg";
// import userIcon from "../../images/user-icon.svg";
// import settingIcon from "../../images/setting-icon.svg";
// import closeIcon from "../../images/close-icon.svg";
// import rehaSidebarLogo from "../../images/reha-sidebar-logo.svg";
// import logoutIcon from "../../images/logout.svg";
import SendEmailCopyModal from "../modal/SendEmailCopyModal.js";
import Sidebar from "../comman/Sidebar";
import UserService from "../../services/UserService";
import { reportTimezone } from "../../services/localtime";
import { useMessages } from "../../hooks";
import suggestions from "../suggestion.json";
import CheckInInputArea from "./CheckInInputArea";
import CheckInChatWindow from "./CheckInChatWindow";
import PhoneNumberVerificationModal from "./../modal/VerifyPhoneNumber.js";
import GetPhoneNumberModal from "../modal/GetPhoneNumberModal.js";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase.js";
import SignInNudgeModal from "./../modal/SignInNudgeModal.js";
import SignOutModal from "../modal/SignOutModal.js";
import Header from "../comman/Header.js";
import { SidebarProvider } from "../comman/SidebarProvider.js";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

function shuffleArray(array) {
   for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
   }
}
function selectDiversePrompts(questions, count) {
   shuffleArray(questions);
   const prompts = [];
   const topicsSeen = new Set();
   for (const question of questions) {
      if (prompts.length >= count) break;
      if (!topicsSeen.has(question.tag)) {
         topicsSeen.add(question.tag);
         prompts.push({ question: question.question, tag: question.tag });
      }
   }
   return prompts;
}

const ChekInComponent = () => {
   const [showSentCopyEmail, setShowSendCopyEmail] = useState(false);
   const [isSignedIn, setIsSignedIn] = useState(false);
   // const [subscriptionID, setSubscriptionID] = useState(false);
   const [userEmail, setUserEmail] = useState("");
   const { messages, sendMessage, isTyping } = useMessages();
   const [showSuggestions, setShowSuggestions] = useState(true);
   const [suggestionPrompts, setSuggestionPrompts] = useState([]);
   // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
   const chatWindowRef = useRef(null);
   const activityTimeoutRef = useRef(null);
   const navigate = useNavigate();
   const [isDisabled, setIsDisabled] = useState(false);
   // const [isPremium, setIsPremium] = useState("");
   const [inputValue, setInputValue] = useState("");
   const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
   const [showVerificationModal, setShowVerificationModal] = useState(false);
   const [phoneNumberExists, setPhoneNumberExists] = useState(false);
   const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false);
   const [showSignInNudgeModal, setShowSignInNudgeModal] = useState(false);
   const [showSignOutModal, setShowSignOutModal] = useState(false);
   const [errorMessage, setErrorMessage] = useState(""); 
   const messageCountRef = useRef(0);

   useEffect(() => {
      initFlowbite();
   }, []);

   const resetActivityTimeout = () => {
      clearTimeout(activityTimeoutRef.current);
      activityTimeoutRef.current = setTimeout(() => {
         setShowSuggestions(true);
         console.log("User has been inactive for 1 minute, showing suggestions."); //needs logic which can show the inferred prompts instead of the normal ones.
      }, 60000);
   };

   useEffect(() => {
      window.addEventListener("keydown", resetActivityTimeout);
      window.addEventListener("mousemove", resetActivityTimeout);

      return () => {
         window.removeEventListener("keydown", resetActivityTimeout);
         window.removeEventListener("mousemove", resetActivityTimeout);
         clearTimeout(activityTimeoutRef.current);
      };
   }, []);

   useEffect(() => {
      const fetchProfile = async () => {
         try {
            // let profile = await UserService.getProfile();
            // console.log(profile);

            // if (profile.is_premium) {
            //    setIsPremium(true);
            // } else {
            //    setIsPremium(false);
            // }
         } catch (error) {
            console.error("Error fetching profile:", error);
         }
      };

      fetchProfile();
   }, []);

   const handleInputChange = (e) => {
      setInputValue(e.target.value);
   };

   const handleFormSubmit = async (e) => {
      e.preventDefault();
      if (!inputValue.trim()) return;

      await sendMessage(inputValue.trim());
      setInputValue("");
      messageCountRef.current += 1;
      setShowSuggestions(false);

      console.log(`Updated message count: ${messageCountRef.current}`);

      if (messageCountRef.current === 7 && !isSignedIn) {
        setShowSignInNudgeModal(true);
      }
   };



   useEffect(() => {
      const promptCount = window.innerWidth < 768 ? 2 : 3;

      setSuggestionPrompts(
         selectDiversePrompts(suggestions.questions, promptCount)
      );
   }, []);
   useEffect(() => {
      reportTimezone(); //this is reporting the timezone, connection with the backend is needed.
   }, []);

   useEffect(() => {
      const chatElement = chatWindowRef.current;
      if (chatElement) {
         setTimeout(() => {
            chatElement.scrollTop = chatElement.scrollHeight;
         }, 100);
      }
      if (messages && messages.length > 0) {
         const lastMessage = messages[messages.length - 1];
         if (lastMessage.isLimitExceed === 1) {
            setIsDisabled(true);
         }
      }
   }, [messages]);
   const generateSessionId = () => {
      return `session_${Math.random().toString(36).substr(2, 9)}_${Date.now()}`;
    };
   

   useEffect(() => {
      const sessionId = generateSessionId();
      if (process.env.NODE_ENV === "production") {
         ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }
      const fetchSignInStatus = async () => {
         const data = await UserService.getSignInStatus();
         const storedChatId = localStorage.getItem("chat_id");
         const isShowWelcome = localStorage.getItem("isShowWelcome") === "true";

         // add unauthenticated session
         if(!data.authenticated){
            logEvent(analytics, "un_authenticated_sessions", {
              session_id: sessionId,
              timestamp: new Date().toISOString(),
              category : "activation_rate",
              page : window.location.pathname,
            });
         }

         
         if (data.chat_id !== storedChatId) {
            if(storedChatId !== "null") {
               localStorage.removeItem("isShowWelcome");
            }
            localStorage.setItem("chat_id", data.chat_id);
            localStorage.removeItem("isfirstMessage");
            localStorage.removeItem("isUserStayedAround");
         }
         //show welcome page when first time visit
         navigate(
            isShowWelcome
               ? data.chat_id !== storedChatId
                  ? "/welcome"
                  : "/"
               :  "/welcome" 
         );

         setIsSignedIn(data.authenticated);
         // setSubscriptionID(data.subscription_id);
         localStorage.setItem("isSignedIn", JSON.stringify(data));
         if (data.authenticated) {
            setUserEmail(data.email);
            reportTimezone();
            const storedPhone = localStorage.getItem("phone");
            if (storedPhone) {
               try {
                  console.log(storedPhone);
                  const typeOfPhone = typeof storedPhone;
                  console.log(typeOfPhone);
                  const updatedProfile = await UserService.updatePhoneNumber(
                     storedPhone
                  );
                  if (updatedProfile) {
                     console.log("Phone number updated successfully:", updatedProfile);
                     localStorage.removeItem("phone");
                  } else {
                     console.error("Failed to update phone number");
                  }
               } catch (error) {
                  console.error("Error updating phone number:", error);
               }
            }
         }
      };

      fetchSignInStatus();
   }, [navigate]);

   useEffect(() => {
      const checkPhoneNumberStatus = async () => {
        if (isSignedIn) {
          try {
            const data = await UserService.getProfile();
  
            const phoneExists = data.phone_number !== null;
            const verified = data.phone_number_verified;
  
            setPhoneNumberExists(phoneExists);
            setPhoneNumberVerified(verified);
  
            if (phoneExists && !verified) {
              setShowVerificationModal(true);
            }
          } catch (error) {
            console.error("Error fetching phone number status:", error);
            setPhoneNumberExists(false);
            setPhoneNumberVerified(false);
          }
        }
      };
      const checkOnboardingStatus = async () => {
         if (isSignedIn) {
           try {
             const data = await UserService.getProfile();
   
             const onboardingDone = data?.onboarding_completed;
   
             if (!onboardingDone) {
               navigate("/onboard");
             }
           } catch (error) {
             console.error("Error fetching phone number status:", error);
           }
         }
       };
       checkOnboardingStatus(navigate);
       checkPhoneNumberStatus();
     }, [
       isSignedIn,
       setPhoneNumberExists,
       setPhoneNumberVerified,
       setShowVerificationModal,
       navigate,
     ]);

     useEffect(() => {
      console.log(`Message count is currently: ${messageCountRef.current}`);
      if (
        messageCountRef.current === 10 &&
        isSignedIn &&
        !phoneNumberExists &&
        !showPhoneNumberModal
      ) {
        console.log("Showing phone number modal");
        setShowPhoneNumberModal(true);
      }
      const isNotFirstMessage = localStorage.getItem("isfirstMessage") !== "true";
      const isUserNotStayedAround = localStorage.getItem("isUserStayedAround") !== "true";
      if(messageCountRef.current === 1 && isNotFirstMessage){
        logEvent(analytics, "first_message", { page : window.location.pathname , category : "adoption_rate"});
        localStorage.setItem("isfirstMessage", "true");
        //console.log("First message event logged")
      }
      if(messageCountRef.current === 15 && isUserNotStayedAround){
        // console.log("Stayed around event logged");
        logEvent(analytics, "stayed_around", { message_count: 15,  page : window.location.pathname , category : "adoption_rate" });
        localStorage.setItem("isUserStayedAround", "true");
      }
    }, [messages, isSignedIn, phoneNumberExists, showPhoneNumberModal]);
  
   const handleVerify = async (code) => {
      try {
        const response = await fetch(`${API_BASE_URL}/auth/verify_sms_code`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code }),
        });
  
        if (!response.ok) {
          throw new Error("Failed to verify code");
        }
  
        const data = await response.json();
  
        if (data.verified) {
          console.log("Verification successful:", data);
          setPhoneNumberVerified(true);
          setShowVerificationModal(false);
          setShowPhoneNumberModal(false);
          setErrorMessage(""); // Clear any existing error message on success
        } else {
          console.log("Verification failed:", data);
          setErrorMessage(
            "Invalid or expired code. Try again or request for a new one."
          ); // Set error message on failure
        }
      } catch (error) {
        console.error("Error verifying code:", error);
        setErrorMessage("An error occurred. Please try again."); // Set error message on error
      }
    };
  
    const closeVerifyNumberModal = () => {
      setShowVerificationModal(false);
      setShowPhoneNumberModal(false);
      messageCountRef.current += 1;
    };

    const closePhoneNumberModal = () => {
      setShowPhoneNumberModal(false);
      messageCountRef.current += 1;
    };

    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({ top: containerRef.current.scrollHeight, behavior: "smooth" });
        }
    }, [messages, isTyping]);

   return <React.Fragment>
        <SidebarProvider>
      <div className="flex flex-col justify-between h-full bg-primary">
      <Header />
         <main className="flex-1 overflow-auto" ref={containerRef}>
            <section className="container">
               <CheckInChatWindow messages={messages ?? []} isTyping={isTyping} />
            </section>
         </main>
         <footer className="pt-2">
            <section className="container">
               <div className="grid xs:grid-cols-1 lg:grid-cols-3 xs:gap-2 lg:gap-4 mb-3">
                  {showSuggestions}
                  {showSuggestions && !isDisabled && (
                     suggestionPrompts.map((prompt, index) => (
                        <article key={index}>
                           {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                           <button className="w-full text-left h-full"
                              onClick={() => {
                                 sendMessage(prompt.question);
                                 setShowSuggestions(false);
                              }}>
                              <div className="relative h-full bg-gradient-to-l from-purple-200 to-pink-200 p-[1px] rounded-xl">
                                 <div className="bg-gradient-to-l h-full from-purple-100 to-pink-100 p-4 rounded-xl hover:opacity-90 shadow-sm">
                                    <p className="text-gray-800 font-medium"><span className="font-semibold text-secondary ">
                                       {prompt.tag}: </span>
                                       {prompt.question}</p>
                                 </div>
                              </div>
                           </button>
                        </article>
                     ))
                  )}
                   </div>
                  
                  {isDisabled && (
                     <div className="text-white lg:text-[17px] leading-snug bg-gradient-to-l from-purple-900 to-purple-800 px-5 py-4 rounded-[20px] mb-2">
                     <article>
                        <div className="text-xl">
                           💁 Rhea would love to continue this conversation with you but
                           unfortunately you have reached your free usage limit.{" "}
                           <b>
                              {" "}
                              <Link to="/upgrade-plan" className="underline">
                                 {" "}
                                 Upgrade your account to enjoy the full experience.
                              </Link>
                           </b>
                        </div>
                     </article>
                     </div>
                  )}
               <div className="flex items-center flex-wrap">
                  <CheckInInputArea
                     inputValue={inputValue}
                     onInputChange={handleInputChange}
                     onFormSubmit={handleFormSubmit}
                     isDisabled={isDisabled} />
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <button className="pl-3" onClick={() => setShowSendCopyEmail(true)}>
                     <img src={shareIcon} alt="" />
                  </button>
                  {isSignedIn ? <p className="w-full text-center xs:text-[10px] lg:text-[14px] text-gray-800 xs:mt-1 lg:mt-3">You are signed in as {userEmail}</p> : <p className="w-full text-center text-[14px] text-gray-800 mt-3">You are not signed in. Rhea’s responses will not be personalized.</p>}
               </div>
               <div className="flex justify-between my-4">
                  <p className="xs:text-[12px] lg:text-[14px] text-gray-800">©{new Date().getFullYear()} Be Human(e), co.</p>
                  <ul className="flex items-center">
                     <li className="inline-block pr-6">
                        <a href="mailto:support@behumane.co" className="xs:text-[12px] lg:text-[14px] underline underline-offset-4 decoration-gray-300 ">Need Help?</a>
                     </li>
                     <li>
                        <a target="_blank" href="https://www.behumane.ai/privacy-policy" rel="noreferrer" className="xs:text-[12px] lg:text-[14px] underline underline-offset-4 decoration-gray-300">Privacy Policy</a>
                     </li>
                  </ul>
               </div>
            </section>
         </footer>
      </div>
      <GetPhoneNumberModal
         isOpen={showPhoneNumberModal}
         onClose={() => closePhoneNumberModal()}
      />
      <SignOutModal
        isOpen={showSignOutModal}
        onClose={() => setShowSignOutModal(false)}
      />
      <SignInNudgeModal
         isOpen={showSignInNudgeModal}
         onClose={() => setShowSignInNudgeModal(false)}
      />
      <PhoneNumberVerificationModal
         isOpen={
            showVerificationModal && phoneNumberExists && !phoneNumberVerified
         }
         onClose={() => closeVerifyNumberModal()}
         onVerify={handleVerify}
         errorMessage={errorMessage} // Pass the errorMessage to the modal
      />
      {showSentCopyEmail && (<SendEmailCopyModal
         isOpen={showSentCopyEmail}
         onClose={() => setShowSendCopyEmail(false)}
      />)}
      <Sidebar setSignOutModal={setShowSignOutModal} />
      </SidebarProvider>
   </React.Fragment>
}

export default ChekInComponent;